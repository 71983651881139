import React from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';

import { getListingsById } from '../../../ducks/marketplaceData.duck';
import { FormattedMessage } from '../../../util/reactIntl';
import SearchResultsPanel from '../../SearchPage/SearchResultsPanel/SearchResultsPanel';
import Field from '../Field';

import css from './SectionSearchResults.module.css';

// use the "Columns" section type in the console please
function SectionSearchResultsComponent(props) {
  const { defaultClasses, listings, title, options }      = props;
  const { pageId }        = useParams();
  const pagination        = useSelector(state => state.hostedAssets?.searchResultsData?.[pageId]?.meta);
  const hasPaginationInfo = !!pagination && pagination.totalItems != null;
  const totalItems        =
          hasPaginationInfo
            ? pagination.totalItems
            : pagination?.paginationUnsupported
              ? listings.length
              : 0;
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  return <div className={css.searchListingsPanel}>
    <header className={`${defaultClasses.sectionDetails, css.listingHeader}`}>
      <Field data={title} className={defaultClasses.title} options={fieldOptions} />
    </header>
    {/* <div className={css.searchResultSummary}>
      <FormattedMessage
        id="MainPanelHeader.foundResults"
        values={{ count: totalItems }}
      />
    </div> */}
    <SearchResultsPanel
      listings={listings}
      pagination={null}
      isMapVariant={false}
    />
  </div>;
}

function shuffleIds(arr) {
  try{
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
  }catch(e){
    return [];
  }
}

function mapStateToProps(state) {
  const { searchResultsData, currentPageAssets } = state.hostedAssets || {};
  const currentPageResultIds = shuffleIds(currentPageAssets.flatMap(pageID => searchResultsData?.[pageID]?.data ?? []).map(l => l.id));
  //Show first 20 listings after shuffle.
  const listings = getListingsById(state, currentPageResultIds.slice(0,20));
  return { listings };
}

const SectionSearchResults = compose(
  connect(mapStateToProps)
)(SectionSearchResultsComponent);

export default SectionSearchResults;
